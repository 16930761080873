import React from "react";
import { Link } from "@remix-run/react";
import SiYoutube from "@icons-pack/react-simple-icons/icons/SiYoutube.mjs";
import { ExternalLink, PlayCircle } from "lucide-react";

interface VideoLinkProps {
	title: string;
	videoId: string;
	videoUrl: string;
	timestamp?: string;
	className?: string;
	showExternal?: boolean;
	size?: "sm" | "md" | "lg";
}

const VideoLink = ({
	videoId,
	title,
	videoUrl,
	timestamp,
	className = "",
	showExternal = false,
	size = "md",
}: VideoLinkProps) => {
	// タイムスタンプを秒に変換
	const timeToSeconds = (timestamp: string): number => {
		const parts = timestamp.split(":").map(Number);
		if (parts.length === 3) {
			return parts[0] * 3600 + parts[1] * 60 + parts[2];
		}
		return parts[0] * 60 + parts[1];
	};

	const sizes = {
		sm: "text-sm",
		md: "text-base",
		lg: "text-lg",
	};

	return (
		<div
			className={`flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:justify-between ${className}`}
		>
			{/* 動画詳細ページへのリンク */}
			<Link
				to={`/videos/${videoId}`}
				className={`inline-flex items-center hover:text-blue-600 ${sizes[size]} flex-1 min-w-0`}
			>
				<span className="line-clamp-3 text-sm">{title}</span>
			</Link>

			{/* YouTubeへの外部リンク - 常に表示 */}
			<a
				href={`${videoUrl}${timestamp ? `&t=${timeToSeconds(timestamp)}` : ""}`}
				target="_blank"
				rel="noopener noreferrer"
				className="inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 w-30 justify-center flex-shrink-0"
				title="YouTubeで開く"
			>
				<SiYoutube className="w-4 h-4 mr-2" />
				YouTube
			</a>
		</div>
	);
};

export default VideoLink;
